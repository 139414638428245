import React from 'react'
import {graphql} from 'gatsby'
import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import Layout from '../components/layout'
import blogpoststyle from './blogpost.module.scss'
import Head from '../components/head'



// export const query = graphql`
// query(
//     $slug: String!
//   ) {
//     markdownRemark (
//       fields: {
//         slug: {
//           eq: $slug
//         }
//       }
//     ) {
//       frontmatter {
//         title
//         date
//       }
//       html
//     }
//   }
// `


export const query = graphql`

query($slug: String!) {
  contentfulBlogPost (slug: {eq: $slug}) {
    title
    author
    category
    publishedDate(formatString: "MMMM Do, YYYY")
    body{
      json
    }
  }
}`




const Blog = (props) => {


    const options = {
      renderNode: {
        "embedded-asset-block" : (node) => {
          const alt = node.data.target.fields.title['en-US']
          const url = node.data.target.fields.file['en-US'].url
          return <img alt={alt} src={url}/>
        }
      }
    }
    return (
        <Layout>
          <div className={blogpoststyle.container}>
          <Head title={props.data.contentfulBlogPost.title} />
            <h1 className={blogpoststyle.h}>{props.data.contentfulBlogPost.title}</h1>
            <p className={blogpoststyle.date}>{props.data.contentfulBlogPost.publishedDate}</p>
            <p className={blogpoststyle.p}>By <span className={blogpoststyle.author}>{props.data.contentfulBlogPost.author} </span>in <span className={blogpoststyle.category}>{props.data.contentfulBlogPost.category}</span></p>

            <div className={blogpoststyle.content}>
                          {documentToReactComponents(props.data.contentfulBlogPost.body.json, options)}

            </div>

            </div>
        
        </Layout>
    )
}


export default Blog


//           <div  dangerouslySetInnerHTML = {{ __html: props.data.markdownRemark.html}}></div>
